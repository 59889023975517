import React from 'react';

// Import all SVG icons
import User from '/public/icons/user.svg';
import Bell from '/public/icons/bell.svg';
import Lock from '/public/icons/lock.svg';
import LocationPin from '/public/icons/location-pin.svg';
import DeliveryBox from '/public/icons/delivery-box.svg';
import ArrowDownOrange from '/public/icons/arrow-down-orange.svg';
import Hamburgur from '/public/icons/hamburgur.svg';
import Food from '/public/icons/food.svg';
import Search from '/public/icons/search.svg';
import Tickmark from '/public/icons/tickmark.svg';
import Close from '/public/icons/close.svg';
import Lift from '/public/icons/lift.svg';
import Beverage from '/public/icons/beverage.svg';
import Parking from '/public/icons/parking.svg';
import Chair from '/public/icons/chair.svg';
import Menu from '/public/icons/menu.svg';
import Veg from '/public/icons/veg.svg';
import Nonveg from '/public/icons/nonveg.svg';
import Kid from '/public/icons/kid.svg';
import Clock from '/public/icons/clock.svg';
import Bbq from '/public/icons/bbq.svg';
import Facebook from '/public/icons/facebook.svg';
import Youtube from '/public/icons/youtube.svg';
import Twitter from '/public/icons/twitter.svg';
import Insta from '/public/icons/insta.svg';
import RightArrow from '/public/icons/right-arrow.svg';
import RightArrowWhite from '/public/icons/right-arrow-white.svg';
import SliderRight from '/public/icons/slider-right.svg';
import IcRoundStar from '/public/icons/ic_round-star.svg';
import Coupon from '/public/icons/coupon.svg';
import Smily from '/public/icons/smily.svg';
import ArrowUp from '/public/icons/arrowUp.svg';
import ArrowLeft from '/public/icons/arrow-left.svg';
import RestCardBtn1 from '/public/icons/restCardBtn1.svg';
import RestCardBtn2 from '/public/icons/restCardBtn2.svg';
import ArrowDown from '/public/icons/arrowDown.svg';
import Sort from '/public/icons/sort.svg';
import Filter from '/public/icons/filter.svg';
import SearchBlack from '/public/icons/searchBlack.svg';
import Reservation from '/public/icons/Reservations.svg';
import Catering from '/public/icons/Catering.svg';
import HappinessCard from '/public/icons/HappinessCard.svg';
import Delivery from '/public/icons/Delivery.svg';
import Location from '/public/icons/location.svg';
import Call from '/public/icons/call.svg';
import Share from '/public/icons/share.svg';
import LiftIcon from '/public/icons/liftIcon.svg';
import Beverages from '/public/icons/beverages.svg';
import ParkingIcon from '/public/icons/parkingIcon.svg';
import Chairs from '/public/icons/chairs.svg';
import Ac from '/public/icons/ac.svg';
import TakeAway from '/public/icons/takeAway.svg';
import WheelChair from '/public/icons/wheelChair.svg';
import Music from '/public/icons/music.svg';
import SliderRightMob from '/public/icons/sliderRightMob.svg';
import TickIcon from '/public/icons/tick-icon.svg';
import Package from '/public/icons/package.svg';
import Clock16 from '/public/icons/clock16.svg';
import MinusIcon from '/public/icons/minus.svg';
import PlusIcon from '/public/icons/plus.svg';
import DeliveryLocation from '/public/icons/delivery-location.svg';
import FilterDesktop from '/public/icons/filterDesktop.svg';
import MinusCounter from '/public/icons/minusCounter.svg';
import PlusCounter from '/public/icons/plusCounter.svg';
import Amenities from '/public/icons/amenities.svg';
import OrderConfirm from '/public/icons/orderConfirm.svg';
import BeverageWhite from '/public/icons/beverageWhite.svg';
import VegLeaf from '/public/icons/vegLeaf.svg';
import CouponApplied from '/public/icons/couponApplied.svg';
import ParkingBlue from '/public/icons/parkingBlue.svg';
import LocationBlue from '/public/icons/locationBlue.svg';
import CallBlue from '/public/icons/callBlue.svg';
import ShareOrange from '/public/icons/shareOrange.svg';
import Reshaduled from '/public/icons/reshaduled.svg';
import DownArrow from '/public/icons/down-arrow.svg';
import MenuIcon from '/public/icons/Menu-Icon.svg';
import SadEmoji from '/public/icons/sadEmoji.svg';
import Calender from '/public/icons/calender.svg';
import Arowdown24 from '/public/icons/arowdown24.svg';
import SmilesEmoji from '/public/icons/Smiles-emoji.svg';
import Logout from '/public/icons/logout.svg';
import Info from '/public/icons/info.svg';
import Cancel from '/public/icons/cancel.svg';
import Reschedule from '/public/icons/reschedule.svg';
import CallOrange from '/public/icons/call-orange.svg';
import MapOrange from '/public/icons/mapOrange.svg';
import ShareIconOrange from '/public/icons/share-orange.svg';
import BackLeftArrow from '/public/icons/backLeftArrow.svg';
import CurrentLocation from '/public/icons/current-location.svg';
import LocationPinBlack20 from '/public/icons/locationPinBlack20.svg';
import Gps from '/public/icons/gps.svg';
import Home from '/public/icons/home.svg';
import Outlet from '/public/icons/outlet.svg';
import InfoOutlet from '/public/icons/infoOutlet.svg';
import Razorpay from '/public/icons/razorpay.svg';
import LocationSearchIcon from '/public/icons/locationSearchIcon.svg';
import ChangeLoc from '/public/icons/change-loca.svg';
import DeliveryHistory from '/public/icons/delivery-history.svg';

// Map icon names to components
const icons = {
    user: User,
    smilesEmoji: SmilesEmoji,
    downArrow: DownArrow,
    menuIcon: MenuIcon,
    filterDesktop: FilterDesktop,
    package: Package,
    minusIcon: MinusIcon,
    plusIcon: PlusIcon,
    tickIcon: TickIcon,
    delivery: Delivery,
    happinessCard: HappinessCard,
    catering: Catering,
    reservation: Reservation,
    bell: Bell,
    lock: Lock,
    locationPin: LocationPin,
    deliveryBox: DeliveryBox,
    arrowDownOrange: ArrowDownOrange,
    Hamburgur: Hamburgur,
    food: Food,
    search: Search,
    tickmark: Tickmark,
    close: Close,
    lift: Lift,
    beverage: Beverage,
    parking: Parking,
    chair: Chair,
    menu: Menu,
    veg: Veg,
    nonveg: Nonveg,
    kid: Kid,
    clock: Clock,
    bbq: Bbq,
    Facebook: Facebook,
    youtube: Youtube,
    twitter: Twitter,
    insta: Insta,
    rightArrow: RightArrow,
    rightArrowWhite: RightArrowWhite,
    sliderRight: SliderRight,
    icRoundStar: IcRoundStar,
    coupon: Coupon,
    smily: Smily,
    arrowUp: ArrowUp,
    arrowLeft: ArrowLeft,
    restCardBtn1: RestCardBtn2,
    restCardBtn2: RestCardBtn1,
    arrowDown: ArrowDown,
    sort: Sort,
    filter: Filter,
    searchBlack: SearchBlack,
    location: Location,
    call: Call,
    share: Share,
    liftIcon: LiftIcon,
    beverages: Beverages,
    parkingIcon: ParkingIcon,
    chairs: Chairs,
    ac: Ac,
    takeAway: TakeAway,
    weelChair: WheelChair,
    music: Music,
    sliderRightMob: SliderRightMob,
    clock16: Clock16,
    deliveryLocation: DeliveryLocation,
    minusCounter: MinusCounter,
    plusCounter: PlusCounter,
    amenities: Amenities,
    orderConfirm: OrderConfirm,
    beverageWhite: BeverageWhite,
    vegLeaf: VegLeaf,
    couponApplied: CouponApplied,
    parkingBlue: ParkingBlue,
    locationBlue: LocationBlue,
    callBlue: CallBlue,
    shareOrange: ShareOrange,
    reshaduled: Reshaduled,
    sadEmoji: SadEmoji,
    calender: Calender,
    arowdown24: Arowdown24,
    logout: Logout,
    info: Info,
    cancel: Cancel,
    reschedule: Reschedule,
    callOrange: CallOrange,
    mapOrange: MapOrange,
    shareIconOrange: ShareIconOrange,
    backLeftArrow: BackLeftArrow,
    currentLocation: CurrentLocation,
    locationPinBlack20: LocationPinBlack20,
    gps: Gps,
    home: Home,
    outlet: Outlet,
    infoOutlet: InfoOutlet,
    razorpay: Razorpay,
    locationSearchIcon: LocationSearchIcon,
    changeLoc: ChangeLoc,
    deliveryHistory: DeliveryHistory,
};

export type IconName = keyof typeof icons;

type IconProps = {
    name: IconName;
    size?: number;
    className?: string;
    color?: string;
};

const Icon: React.FC<IconProps> = ({ name, size = 24, color, className }) => {
    const IconComponent = icons[name];
    return <IconComponent width={size} height={size} fill={color ? color : 'transparent'} className={className} />;
};

export default Icon;